import { FC } from "react"
import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"

import config from "../../config.json"
import { MortgageComparison } from "../views/MortgageComparison"
import { SEOVariantTag } from "../views/MortgageComparison/utils/types"

interface SEOData {
  title: string
  metaDescription: string
  ltvAmount: number
  h1Heading: string
  introParagraph: string
  canonicalUrl: string
}

export const Head: FC<{
  pageContext: { metadata: SEOData }
}> = ({ pageContext: { metadata } }) => {
  const ltv = metadata.ltvAmount

  return (
    <WrappedHead
      metaTitle={metadata.title}
      metaDescription={metadata.metaDescription}
      canonicalUrl={metadata.canonicalUrl}
      noIndex={false}
      pageName={`mc-ltv-${ltv}`}
      intercom={true}
      config={config}
    />
  )
}

const ComparisonPage: FC<{
  pageContext: { metadata: SEOData }
}> = ({ pageContext: { metadata } }) => {
  const ltv = metadata.ltvAmount

  return (
    <WrappedBase>
      <MortgageComparison seoVariant={{ tag: SEOVariantTag.LTV, ltv: ltv }} />
    </WrappedBase>
  )
}

export default ComparisonPage
